// Sidebar
//
// Sidebar component.

.sidebar {
  background: $blue;
  color: $white;
  box-shadow: $box-shadow;
  display: none;

  a {
    color: $white;
  }

  @include mq(lg) {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    overflow-y: auto;
  }

  .logo path {
    fill: $white;
  }

}

.sidebar__title {
  color: $white;
}


.sidebar--menu {

  @include mq(lg, max) {
    display: block;
    height: 100%;
    min-width: 250px;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;

    z-index: $z-index-menu;

    .logo path {
      fill: $white;
    }

    .button {
      padding-left: 0;

      &:hover {
        background: inherit;
      }
    }
  }
}

// Body
// ---------------------------------------------------------

.sidebar__body {
  width: 100%;
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
  background-size: cover;
}

// Head
// ---------------------------------------------------------

.sidebar__head {
  background: $white;
  border-bottom: 1px solid $grey-light;
  padding: $spacer * 6;
  color:$black;

  .sidebar__title,
  a {
    color: $black;
  }

  @include mq(lg) {
    display: flex;
  }
}

// Logo
// ---------------------------------------------------------

.sidebar__logo {
  margin-bottom: $spacer * 3;

  @include mq(lg) {
    margin-bottom: inherit;
    margin-right: $spacer * 3;
  }
}

// Group
// ---------------------------------------------------------

.sidebar__group {
  padding: $spacer * 4 $spacer * 6;
  width: 100%;

  a {
    border-radius: $border-radius;
    display: inline-block;
    padding: $spacer * 2;
    transition: $transition;
    width: 100%;

    @include mq(lg) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.sidebar__logo_container {
  padding: $spacer * 6 $spacer * 6 $spacer * 2 $spacer * 6;
}

// Active
// ---------------------------------------------------------

.sidebar__group {
    .active {
        a {
            background: transparentize($blue, .4);
            color: $white;
        }

        .icon circle,
        .icon path {
            stroke: $white;
        }

        &:hover {
          .icon circle,
          .icon path {
            stroke: $white;
          }

        }
    }
}
