// Modal
//
// Modal component.

.modal {
  background: $white;
  box-shadow: $box-shadow;
  height: 100%;
  max-width: 1000px;
  opacity: 1;
  overflow: auto;
  padding: $spacer * 8;
  position: fixed;
  right: 0;
  top: 0;
  transition: $transition;
  width: 100%;
  visibility: visible;
  z-index: $z-index-modal;

  @include mq(sm) {
    width: 80%;
  }
}
