// Main
//
// Main component.

.main {
  display: flex;
  flex-wrap: wrap;
}

// Column
// ---------------------------------------------------------

.main__column--primary {
  width: 100%;

  @include mq(lg) {
    flex: 1;
    width: inherit;
  }
}

.main__column--center {
  background: $white;
  box-shadow: $box-shadow;
  width: 100%;

  @include mq(lg) {
    width: inherit;
  }
}

.main__column--secondary {
  padding: $spacer * 6;
  width: 100%;

  @include mq(lg) {
    flex: 4;
    height: 100vh;
    overflow-y: auto;
    padding: $spacer * 10;
    width: inherit;
  }
}

// Shrink
// ---------------------------------------------------------

.main__column--shrink {
  @include mq(lg) {
    flex: 0;
  }
}

// Specific
// ---------------------------------------------------------

a:hover.copy {
  text-decoration: underline;
}
