// Filter
//
// Filter component.

.filter {
  padding: $spacer * 10;

  @include mq(lg) {
    height: 100vh;
    overflow-y: auto;
    padding: $spacer * 6;
  }

  @include mq(xlg) {
    padding: $spacer * 10;
  }
}
