// Form
//
// Form component.

.form {
  width: 100%;
}

// Group
// ---------------------------------------------------------

.form__group:not(:last-child) {
  padding-bottom: $spacer * 6;
}

// Head
// ---------------------------------------------------------

.form__head {
  border-bottom: 1px solid $grey-light;
  padding-bottom: $spacer * 2;
  margin-bottom: $spacer * 8;
  text-align: center;
}
