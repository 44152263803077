// Well
//
// Well component.

.well {
  border: 1px solid $grey-light;
  border-radius: $border-radius;
  padding: $spacer * 8;
  margin-bottom: $spacer * 10;
}
