// Headings
//
// Base styles for headings 1 to 6.

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $black;
  font-weight: $font-weight-bold;
}

h1,
.h1 {
  @include font-size(1);
}

h2,
.h2 {
  @include font-size(2);
}

h3,
.h3 {
  @include font-size(3);
}

h4,
.h4 {
  @include font-size(4);
}

h5,
.h5 {
  @include font-size(5);
}

h6,
.h6 {
  @include font-size(6);
}
