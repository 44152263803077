// Section
//
// Add spacing top and bottom around content.

.section {
  padding-bottom: $spacer * 8;
  padding-top: $spacer * 8;
}

// Sizes
// ---------------------------------------------------------

.section--xsm {
  padding-bottom: $spacer * 2;
  padding-top: $spacer * 2;
}

.section--sm {
  padding-bottom: $spacer * 4;
  padding-top: $spacer * 4;
}

.section--lg {
  padding-bottom: $spacer * 16;
  padding-top: $spacer * 16;
}

.section--xlg {
  padding-bottom: $spacer * 24;
  padding-top: $spacer * 24;
}
