// Base
//
// Base styles.

html, body {
  color: $grey;
  font-family: $font-primary;
  font-size: $font-size-mobile;
  line-height: 1.5;

  @include mq(md) {
    font-size: $font-size-desktop;
  }
}

// Background secondary
// ---------------------------------------------------------

.background-secondary {
  background: $grey-light;
}

.full-width {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.logged-out-page {
  max-width: 1200px;
  margin: 0 auto;
}