// Button
//
// Button component.

.fileInput,
.button {
  background-color: $grey;
  border-color: $grey;
  border-radius: $border-radius;
  border-style: none;
  border-width: 2px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: $font-weight-bold;
  outline: 0;
  padding-bottom: $spacer * 3;
  padding-left: $spacer * 4;
  padding-right: $spacer * 4;
  padding-top: $spacer * 3;
  text-align: center;
  transition: $transition;
  white-space: nowrap;
  margin:0 $spacer;

  &:hover {
    background: darken($grey, 10%);
  }
}

.button.white {
  background-color: $white;
  color: $black;
}

.button.red {
  background-color: $red;
  color: $white;
}

.button--flex-inline {
  display: inline-flex;
}

// Group
// ---------------------------------------------------------

.button-group {
  display: flex;

  > .button {
    background: none;
    border: 1px solid $grey-light;
    color: $grey;

    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &--active {
      background: transparentize($green, .4);
      color: $white;
    }
  }
}

.button-group--stretch {
  justify-content: space-between;

  > .button {
    width: 100%;
  }
}

// Colours
// ---------------------------------------------------------

$button-colours: (
        black: $black,
        white: $white,
        active: $blue,
        info: $orange,
        success: $green,
        error: $red,
);

@each $name, $colour in $button-colours {
  .button--#{$name} {
    background-color: $colour;

    &:hover {
      background-color: darken($colour, 10%);
    }
  }
}

// Sizes
// ---------------------------------------------------------

.button--stretch {
  display: inline-flex;
  justify-content: space-between;
  padding-left: $spacer * 5;
  padding-right: $spacer * 5;
  text-align: left;
  width: 180px;
}
