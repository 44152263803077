// Spacing
//
// Some helper classes to help with general spacing.

$spacing-sizes: (
        none: 0,
        xsm: $spacer * 2,
        sm: $spacer * 4,
        md: $spacer * 8,
        lg: $spacer * 16,
        xlg: $spacer * 24
);

$spacing-directions: (
        'top',
        'right',
        'bottom',
        'left'
);

$spacing-properties: (
        'padding',
        'margin'
);

// Base
// ---------------------------------------------------------

@each $property in $spacing-properties {
  @each $direction in $spacing-directions {
    @each $size, $value in $spacing-sizes {
      .#{$property}-#{$size} {
        #{$property}: $value;
      }
    }
  }
}

// Direction
// ---------------------------------------------------------

@each $property in $spacing-properties {
  @each $direction in $spacing-directions {
    @each $size, $value in $spacing-sizes {
      .#{$property}-#{$direction}-#{$size} {
        #{$property}-#{$direction}: $value;
      }
    }
  }
}
