// Text
//
// Text helper classes.

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

$text-sizes: (
        xsm: $spacer * 2,
        sm: $spacer * 4,
        md: $spacer * 8,
        lg: $spacer * 16,
        xlg: $spacer * 32
);

@each $size, $value in $text-sizes {
  .text-#{$size} {
    font-size: $value;
  }
}
