// Data
//
// Data component.

.table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;

  th {
    background: $grey-light;
    padding: $spacer * 4;
  }

  td {
    background: $white;
    padding: $spacer * 4;
  }
  thead {
    border-right: 1px solid $grey-light;
  }
}

// Group
// ---------------------------------------------------------

.table__group {
  border-bottom: 1px solid $grey-light;
  border-left: 1px solid $grey-light;
  border-right: 1px solid $grey-light;

  &:hover .table__data {
      background: $grey-lighter;
  }
}

// Data
// ---------------------------------------------------------

.table__data {
    cursor: pointer;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq(md, max) {
      display: block;
      width: 50%;
    }
  }
}

.table__checkbox {
  width: 5%;
}
