// Card
//
// Card component.

.card {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  padding-bottom: $spacer * 10;
  padding-left: $spacer * 16;
  padding-right: $spacer * 16;
  padding-top: $spacer * 10;
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-right: 20px;
  width: 270px;

  @include mq(sm, max) {
    width: 100%;
  }

  &.active {
    box-shadow: 0 0 8px $blue;
    z-index: 2;
  }

  .icon-flowprofiler-button:before {
    display: none;
  }

  &__report-icon {
    width: 84px;
    height: 84px;
  }
  
}

.card--default {
  cursor: inherit;
}

.card--stretch {
  background: lighten($grey-lighter, 50%);
  box-shadow: none;
  margin-bottom: $spacer * 4;
  padding-bottom: $spacer * 8;
  padding-left: $spacer * 8;
  padding-right: $spacer * 8;
  padding-top: $spacer * 8;
  position: relative;
  text-align: left;
  width: 100%;
}

// Group
// ---------------------------------------------------------

.card-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$spacer * 8;
  margin-left: -$spacer * 8;

  > .card {
    margin-bottom: $spacer * 8;
    margin-left: $spacer * 8;
  }
}

// Title
// ---------------------------------------------------------

.card__title {
  color: $black;
  margin-bottom: 0;
  margin-top: $spacer * 4;
}

// Icon
// ---------------------------------------------------------

.card__icon {
  position: absolute;
  right: 10%;
  top: 10%;
}
