// Colour
//
// Variables & settings for project colours.

// General
// ---------------------------------------------------------

$black: #000;
$white: #fff;

// Greyscale
// ---------------------------------------------------------

$grey: transparentize($black, 0.5);
$grey-light: transparentize($black, 0.9);
$grey-lighter: transparentize($black, 0.95);
$grey-dark: transparentize($black, 0.1);
$grey-darker: transparentize($black, 0.05);

// Branding
// ---------------------------------------------------------

$blue: #005a6d;
$purple: #42025f;
$orange: #cb561f;
$green: #18583d;
$violet: #221d4e;
$brown: #4a2d38;
$red: #e74c3c;
$yellow: #cda338;
