// Select
//
// Select component.

.select {
  border: 1px solid $grey-light;
  font-size: 14px;
  height: $spacer * 11;
  padding: $spacer * 2;
  width: 100%;
  background:$white;

  &:focus {
    border: 1px solid $blue;
    box-shadow: 0 0 8px transparentize($blue, .5);
  }
}

.basic-multi-select {
  min-width:170px !important;  //override
}
