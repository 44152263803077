// Option
//
// Option component.

.options {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

// Icons
// ---------------------------------------------------------

.options__icons {
  display: flex;
}

.options__icon {
  border-radius: 50%;
  display: block;
  height: 8px;
  width: 8px;

  &:not(:last-child) {
    margin-right: $spacer * 2;
  }
}

// Select
// ---------------------------------------------------------

.options__dropdown {
  background: $blue;
  border: 1px solid $blue;
  color: $white;
  padding: $spacer * 3;
  position: absolute;
  margin-top: $spacer * 3;
  right: 0;
  top: 100%;
  z-index: $z-index-options-dropdown;

  &::before {
    background: $blue;
    bottom: calc(100% - 10px);
    content: '';
    height: 20px;
    position: absolute;
    right: 50%;
    transform: translateX(50%) rotate(45deg);
    width: 20px;
  }
}

// Colours
// ---------------------------------------------------------

.options__icon--grey {
  background: $grey;
}

.options__icon--blue {
  background: $blue;
}

.options__icon--yellow {
  background: $yellow;
}

.options__icon--purple {
  background: $purple;
}
