// List Inline
//
// Display list items in a line.

.list-inline {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    vertical-align: middle;
  }
}
