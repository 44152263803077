.cta {
  color: #ffffff;
  background: $blue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  outline: 0;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  text-align: center;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
  margin: 0 4px;

  &:hover {
    background-color: #00303a;    
  }
}