// Icon
//
// Icon component.

.icon {
  display: inline-block;

  circle,
  path {
    transition: $transition;
  }
}

.icon--component {
  cursor: pointer;
}
