// Display
//
// Show & hide elements with these helper classes.

@each $breakpoint-key, $breakpoint-value in $breakpoints {
  .show-#{$breakpoint-key} {
    @include mq($breakpoint-key, max) {
      display: none;
    }
  }
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
  .hide-#{$breakpoint-key} {
    @include mq($breakpoint-key) {
      display: none;
    }
  }
}

// Hidden
// ---------------------------------------------------------

@each $breakpoint-key, $breakpoint-value in $breakpoints {
  .hidden-#{$breakpoint-key} {
    @include mq($breakpoint-key) {
      visibility: hidden;
    }
  }
}
