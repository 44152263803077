// Datepicker
//
// Datepicker component.

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day--selected {
  background-color: $blue;
}
