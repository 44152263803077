// Border
//
// Generate some border helper classes.

$borders: (
        'top',
        'right',
        'bottom',
        'left'
);

.border {
  background-color: transparentize($black, 0.8);
  height: 1px;
  width: 100%;
}

@each $border in $borders {
  .border-#{$border} {
    border-#{$border}: 1px solid transparentize($black, 0.8);
  }
}
