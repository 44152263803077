// Input
//
// Input component for forms.

.input {
  border: 1px solid $grey-light;
  border-radius: $border-radius;
  font-size: 14px;
  outline: 0;
  padding: $spacer * 3;
  width: 100%;

  &:focus {
    border: 1px solid $blue;
    box-shadow: 0 0 8px transparentize($blue, .5);
  }

  &:focus::placeholder {
    color: $black;
  }
}

.date-input {
  background: $white;
  color: $black;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1px;
  text-align: center;
}

// Input Group
// ---------------------------------------------------------

.input-group {
  display: flex;
  flex-direction: row-reverse;

  > .input-search:focus {
    border-left: 0;
    box-shadow: none;

    + .input-button {
      border: 1px solid $blue;
      border-right: 0;
      box-shadow: none;
    }
  }

  &--search-focused {
    input {
      width: 100%;
    }
    button {
      width: 30px;
      padding: 0;
    }
  }
}

.input-group--flip {
  flex-direction: row;

  > .input-search {
    border-bottom-left-radius: $border-radius * 9999;
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-left-radius: $border-radius * 9999;
    border-top-right-radius: 0;

    &:focus {
      box-shadow: none;
      border: 1px solid $blue;
      border-right: 0;

      + .input-button {
        border-left: 0;
        border-right: 1px solid $blue;
      }
    }
  }

  > .input-button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $border-radius * 9999;
    border-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius * 9999;
  }
}

// Search
// ---------------------------------------------------------

.input-search {
  @extend .input;
  height: $spacer * 11;
  border-bottom-right-radius: $border-radius * 9999;
  border-left: 0;
  border-top-right-radius: $border-radius * 9999;
  font-size: inherit;
  padding: $spacer * 1;
  width: 72%;
}

.input-search--bare {
  border-bottom-left-radius: $border-radius * 9999;
  border-top-left-radius: $border-radius * 9999;
}

// Button
// ---------------------------------------------------------

.input-button {
  background: $white;
  border: 1px solid $grey-light;
  border-bottom-left-radius: $border-radius * 9999;
  border-right: 0;
  border-top-left-radius: $border-radius * 9999;
  cursor: pointer;
  outline: 0;
  padding: 0 $spacer * 4;
}

// Checkbox
// ---------------------------------------------------------

input[type='checkbox'] {
    transform: scale(1.2);
}
