// Widths
//
// Helper classes for widths.

@for $i from 1 through 12 {
  .w-#{$i} {
    width: percentage($i / 12);
  }
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
  @include mq($breakpoint-key) {
    @for $i from 1 through 12 {
      .w-#{$i}-#{$breakpoint-key} {
        width: percentage($i / 12);
      }
    }
  }
}

// Fifths
// ---------------------------------------------------------

@each $breakpoint-key, $breakpoint-value in $breakpoints {
  @include mq($breakpoint-key) {
    .w-20-#{$breakpoint-key} {
      width: 20%;
    }
  }
}
