.dropdown {
  position: relative;
  display: inline-block;

  &__selected-option {
    button {
      background: none;
      padding: 12px 20px;
      border: 1px solid $grey-light;
      cursor: pointer;
      border-radius: 4px;
      font-weight: 700;
      outline: 0;
      font-size: 16px;
      width: 100%;
      text-align: left;

      svg {
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  &__options {
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: -4px;
    transform: translateY(-110%);
    background: $white;
    border:1px solid $grey-light;
    border-radius: 4px;
    width: 100%;
    box-shadow: $box-shadow;
  }

  &__item {
    border-bottom: 1px solid $grey-light;

    button {
      padding: 12px 20px;
      background: none;
      border: none;
      width: 100%;
      text-align: center;
      cursor: pointer;
      font-weight: 700;
      outline: 0;
      font-size: 16px;      

      &:hover {
        background: $grey-light;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &--active {
    .dropdown__options {
      opacity: 1;
      z-index: 1;
      transform: translateY(-100%);
      transition: 0.2s transform ease-in, 0.2s opacity ease-in;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  &--candidate-assessment-single {
    min-width: 200px;
    button {
      text-transform: capitalize;
    }
  }
}
