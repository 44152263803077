// List group
//
// List group component.

.list-group {
  display: flex;
}

// Item
// ---------------------------------------------------------

.list-group__item {
  &:first-child {
    padding-right: $spacer * 4;
  }
}
