// Grid
//
// Grid object built with flexbox.

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacer * 6;
  margin-top: -$spacer * 6;
}

// Item
// ---------------------------------------------------------

.grid__item {
  padding-left: $spacer * 6;
  padding-top: $spacer * 6;
  width: 100%;
}

// Gutter Sizes
// ---------------------------------------------------------

.grid--flush {
  margin: 0;

  > .grid__item {
    padding: 0;
  }
}

.grid--xsm {
  margin-left: -$spacer;
  margin-top: -$spacer;

  > .grid__item {
    padding-left: $spacer;
    padding-top: $spacer;
  }
}

.grid--sm {
  margin-left: -$spacer * 2;
  margin-top: -$spacer * 2;

  > .grid__item {
    padding-left: $spacer * 2;
    padding-top: $spacer * 2;
  }
}

.grid--lg {
  margin-left: -$spacer * 8;
  margin-top: -$spacer * 8;

  > .grid__item {
    padding-left: $spacer * 8;
    padding-top: $spacer * 8;
  }
}

.grid--xlg {
  margin-left: -$spacer * 16;
  margin-top: -$spacer * 16;

  > .grid__item {
    padding-left: $spacer * 16;
    padding-top: $spacer * 16;
  }
}

// Vertical Alignment
// ---------------------------------------------------------

.grid--top {
  align-items: flex-start;
}

.grid--middle {
  align-items: center;
}

.grid--bottom {
  align-items: flex-end;
}

.grid--stretch {
  align-items: stretch;
}

// Horizontal Alignment
// ---------------------------------------------------------

.grid--left {
  justify-content: flex-start;
}

.grid--center {
  justify-content: center;
}

.grid--right {
  justify-content: flex-end;
}

.grid--around {
  justify-content: space-around;
}

.grid--between {
  justify-content: space-between;
}
