// Pagination
//
// Pagination component.

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;

  > .pagination__item:not(:first-child) {
    margin-left: $spacer * 4;
  }

  li {
    @extend .pagination__item;

    a {
        color: $white;
    }
  }
}

// Item
// ---------------------------------------------------------

.pagination__item {
    background: $blue;
    border-radius: $border-radius;
    color: $white;
    cursor: pointer;
    padding: $spacer $spacer * 3;
    margin-top: $spacer * 6;
}

// Active
// ---------------------------------------------------------

.pagination__item--active {
  border-bottom: 2px solid $blue;
}
