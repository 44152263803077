.privacy {
  text-align: left;

  &__content,
  &__header {
    padding: 20px;
  }

  ul, ol {
    padding-left: 20px;
  }

  a {
    text-decoration: underline;
  }

  ol {
    ul {
      list-style-type: disc;
      ul {
        list-style-type: circle;
      }
    }
  }
}