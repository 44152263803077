// Colour
//
// Generate some colour helper classes.

$colours: (
        black: $black,
        white: $white,
        grey-lighter: $grey-lighter,
        grey-light: $grey-light,
        grey: $grey,
        grey-dark: $grey-dark,
        grey-darker: $grey-darker,
        active: $blue,
        info: $orange,
        success: $green,
        error: $red
);

@each $colour, $value in $colours {
  .background-#{$colour} {
    background-color: $value;
  }

  .colour-#{$colour} {
    color: $value;
  }
}
