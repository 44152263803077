// Overlay
//
// Overlay component.

.overlay {
  background: $grey;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-overlay;
}
