// Label
//
// Label component for forms.

.label {
  color: $black;
  display: inline-block;
  font-weight: $font-weight-bold;
  padding-bottom: $spacer * 3;
}

// Bare
// ---------------------------------------------------------

.label--bare {
  padding-bottom: 0;
}

// Secondary
// ---------------------------------------------------------

.label--secondary {
  color: $grey;
  font-weight: $font-weight-normal;
}

// Required
// ---------------------------------------------------------

.label--required {
  position: absolute;
  top: -20px;

  &::after {
    content: '*';
    left: 0;
    position: absolute;
    top: 0;
  }
}
