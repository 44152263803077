// Panel
//
// Panel component.

.panel {
  display: flex;
  flex-wrap: wrap;

  &--privacy {
    height: auto;
    
    @include mq(md) {
      height: 100vh;
    }
  }
}

// Item
// ---------------------------------------------------------

.panel__item {
  padding: $spacer * 12;
  width: 100%;

  @include mq(md) {
    flex: 1;
    height: 100vh;
  }

  @include mq(lg) {
    padding: $spacer * 32;
  }

  &:first-child {
    background: $blue;
    text-align: center;

    @include mq(md) {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &:last-child {
    align-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;

    @include mq(lg) {
      padding: $spacer * 32 $spacer * 32 $spacer * 12 $spacer * 32;
    }
  }

  .panel--privacy & {
    overflow-y: auto;
  }
}

.panel__item--center {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

// Icon
// ---------------------------------------------------------

.panel__icon {
  display: inline-block;
  width: 100%;
}

// Block
// ---------------------------------------------------------

.panel__block {
  width: 100%;
  border-top: 1px solid $grey-light;
  padding-top: 10px;  
}
