.help {
  text-align: left;
  width: 100%;

  &__close {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 20px 0;

    button {
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__banner {
    background: $blue;
    padding: 40px 20px;
    width: 100%;
    display: inline-block;

    h1 {
      color: white;
      width: 100%;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .panel__block {
    display: inline-block;

    span {
      width: 100%;
      text-align: center;
      display: block; 
    }
  }

  &__crumbs {
    display: inline-block;
    width: 100%;
    background: $grey-light;
    padding: 5px;
    margin-bottom: 20px;

    &__sect {
      float: left;

      a {
        text-decoration: underline;
      }
    }
  }

  &__topics,
  &__questions {
    float: left;
    width: 50%;

    a {
      margin-bottom: 7px;
      width: 100%;
      display: inline-block;
    }
  }

  &__section {
    &__content {

      a {
        margin-bottom: 7px;
        width: 50%;
        float: left;
      }
    }
  }

  &__content {
    padding: 20px;
    
    h1 {
      font-size: 34px;
    }

    a {
      text-decoration: underline;
    }
  }
  
  &__page {

    ul,
    ol {
      padding-left: 20px;
    }
  }
}