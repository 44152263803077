// Container
//
// Content wrapper object.

.container {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

// Sizes
// ---------------------------------------------------------

.container--sm {
  max-width: 600px;
}
