// Fonts
//
// Webfonts settings.

// Black
// ---------------------------------------------------------

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/SourceSansPro-Black.ttf');
}

// Bold
// ---------------------------------------------------------

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/SourceSansPro-Bold.ttf');
}

// Medium
// ---------------------------------------------------------

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/SourceSansPro-SemiBold.ttf');
}

// Regular
// ---------------------------------------------------------

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/SourceSansPro-Regular.ttf');
}

// Light
// ---------------------------------------------------------

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/SourceSansPro-Light.ttf');
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?owmejj');
  src:  url('../../fonts/icomoon.eot?owmejj#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?owmejj') format('truetype'),
  url('../../fonts/icomoon.woff?owmejj') format('woff'),
  url('../../fonts/icomoon.svg?owmejj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 83.8px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-flowprofiler-logo:before {
  content: "\e900";
  font-size: 50px;
}

.icon-flowprofiler-button:before {
  content: "\e900";
}
.icon-eqflow-button .path1:before {
  content: "\e901";
  color: rgb(0, 90, 109);
}
.icon-eqflow-button .path2:before {
  width: 83px;
  height: 83px;
  content: "\e902";
  margin-left: -1em;
  color: rgb(0, 90, 109);
}
.icon-eqflow-button .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(157, 186, 192);
}
.icon-mflow-button .path1:before {
  content: "\e904";
  color: rgb(204, 86, 31);
}
.icon-mflow-button .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(239, 186, 161);
}
.icon-mflow-button .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(239, 186, 161);
}
.icon-mflow-button .path4:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(239, 186, 161);
}
.icon-mflow-button .path5:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(239, 186, 161);
}
.icon-mflow-button .path6:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(239, 186, 161);
}
.icon-rflow-button .path1:before {
  content: "\e90a";
  color: rgb(66, 2, 95);
}
.icon-rflow-button .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(179, 154, 191);
}
.icon-rflow-button .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(179, 154, 191);
}
.icon-rflow-button .path4:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(179, 154, 191);
}
.icon-rflow-button .path5:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(179, 154, 191);
}
.icon-rflow-button .path6:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(179, 154, 191);
}
