// Alerts
//
// Alerts component.

.alert {
  color: $white;
  left: 0;
  padding: $spacer * 6;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: $z-index-alert;
}

// States
// ---------------------------------------------------------

.alert--success {
  background: transparentize($green, .1);
}

.alert--warning {
  background: transparentize($orange, .1);
}

.alert--error {
  background: transparentize($red, .1);
}

.alert--info {
  background: transparentize($blue, .1);
}

// Close
// ---------------------------------------------------------

.alert__close {
  bottom: 50%;
  cursor: pointer;
  position: absolute;
  right: 5%;
  transform: translate(5%, 50%);

  circle,
  path {
    stroke: $white;
  }
}
