// Tabs
//
// Tabs component.

.tabs {
  align-items: center;
  border-radius: $border-radius;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacer * 4;
}

// Item
// ---------------------------------------------------------

.tabs__item {
  border: 1px solid $blue;
  color: $black;
  cursor: pointer;
  padding: $spacer * 2;
  text-align: center;
  transition: $transition;
  width: 100%;

  &:nth-child(1) {
    border-right: 0;
  }

  &:nth-child(3) {
    border-left: 0;
  }

  @include mq(sm) {
    flex: 0 0 33.333%;
    width: 33.333%;
  }

  &:hover,
  &--active {
    background: transparentize($blue, .4);
    color: $blue;
  }
}
