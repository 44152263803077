// Menu
//
// Menu component for mobile.

.menu {
  cursor: pointer;
  padding: $spacer * 6;
  text-align: right;
  width: 100%;

  @include mq(lg) {
    display: none;
  }
}

// Close
// ---------------------------------------------------------

.menu-close {
  cursor: pointer;
  padding: $spacer * 4;
  text-align: right;

  .icon path {
    stroke: $white;
  }

  @include mq(lg) {
    display: none;
  }
}
