// Font Size
//
// A mixin to generate responsive font sizes.

@mixin font-size($i) {
  font-size: nth($font-size-mobile, $i);

  @include mq(md) {
    font-size: nth($font-size-desktop, $i);
  }
}
